import React from "react";
import Modal from 'react-bootstrap/Modal';
import Maintable from "./Maintable";
import { Table } from "react-bootstrap";


const Mainmodal = (props) => {


  return (
    <>
     <Modal {...props} size="false" className="tc-model-dialog" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="tc-model-header" closeButton>
        <Modal.Title className="tc-model-header-title" id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tc-model-body">
        <h2 className="tc-model-title">{props.heading}</h2>
        <div className="tc-model-text">
            <p>{props.total}</p>
            <p>{props.number}</p>
        </div>
          <Table className="tc-table">
            <tbody>
              {props.data.map((list,i) => {
                  return(
                    <Maintable 
                      name={list.name}
                      qty={list.qty}
                      amount={list.amount}
                    />
                  );
              })}
            </tbody>
          </Table>
      </Modal.Body>
    </Modal>
    </>
  )
};

export default Mainmodal;
