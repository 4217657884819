import React, { useState } from "react";
import Form from 'react-bootstrap/Form';


const Assigment = () => {

  const product = [
    {
        id:'1',
        name:'md',
        qty:'rio',
    },
    {
        id:'2',
        name:'Carlos, Juan',
        qty:'12X',
        class:'tc-text-success'
    },
    {
        id:'3',
        name:'Esguerra, Mike',
        qty:'3X',
        class:'tc-text-danger'
    },
    {
        id:'4',
        name:'Garcia, Jimmy',
        qty:'2X',
        class:'tc-text-danger'
    },
    {
        id:'5',
        name:'Dela Cruz, John',
        qty:'1X',
        class:'tc-text-danger'
    },  
  ]

  return (
      <>
        <div className="tc-assigment-section tc-sidebar-section">
            <div className="tc-product-navbar">
              <div className="tc-product-menu" onClick={() => {document.body.classList.remove('tc-assigment-sidebar');}} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/></svg>
              </div>
              <div className="tc-product-text"><h2>Assigment</h2></div>
            </div>
            <div className="tc-product-body">
                <Form className="tc-assigment-form">  
                    <div className="tc-assigment-form-item">
                        <Form.Group className="tc-assigment-form-list">
                            <Form.Label>MR: </Form.Label>
                            <Form.Select className="tc-assigment-option" aria-label="Default select example">
                                <option value="1">Santos, Nina</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="tc-assigment-form-list">
                            <Form.Label>MR: </Form.Label>
                            <Form.Select className="tc-assigment-option" aria-label="Default select example">
                                <option value="1">Carlos, Juan</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="tc-assigment-form-list">
                            <Form.Label>S.N:</Form.Label>
                            <Form.Control className="tc-assigment-option" type="text" value="EX-02-0011-RP" />
                        </Form.Group>
                        <Form.Group className="tc-assigment-form-list">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control className="tc-assigment-option" type="text" />
                        </Form.Group>
                    </div>
                    <button className="tc-save">save</button>
                </Form>
              </div>
          </div>
      </>
    );
};

export default Assigment;
