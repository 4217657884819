import { faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Form, Table } from "react-bootstrap";
import Secfooter from "../../component/Layout/Secfooter";

const Product1 = () => {

  const [sideShow, setSideShow] = useState(false)
  const [daily, setDaily] = useState({
    series: [{
        name: 'Net Profit',
        data: [13, 11, 12, 10, 10, 4]
    }],
    options: {
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false
          },
        },
        colors: ['#96da7a', '#fef99b'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            distributed: true,

          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#000']
        },
        xaxis: {
          categories: ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'],
        },
        grid: {
            show: false,
        },
        fill: {
          opacity: 1
        },
        legend: {
            show: false,
        },
      },
  })
  const product = [
    {
        id:'1',
        name:'Day',
        qty:'#',
        amount:'₽',
    },
    {
        id:'2',
        name:'1',
        qty:'14',
        amount:'5,000',
    },
    {
        id:'3',
        name:'2',
        qty:'13',
        amount:'5,000',
    },
    {
        id:'4',
        name:'3',
        qty:'13',
        amount:'2,500',
    },
    {
        id:'5',
        name:'4',
        qty:'12',
        amount:'2,500',
    },  
    {
        id:'6',
        name:'5',
        qty:'11',
        amount:'2,500',
    },
    {
        id:'6',
        name:'6',
        qty:'3',
        amount:'2,500',
    },
    {
        id:'7',
        name:'7',
        qty:'0',
        amount:'0',
    },
  ]

  return (
      <>
        <div className="tc-product1-section tc-sidebar-section">
            <div className="tc-product-navbar">
              <div className="tc-product-menu" onClick={() => {document.body.classList.remove('tc-product1-sidebar');}} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/></svg>
              </div>
              <div className="tc-product-text"><h2>Product 1</h2></div>
            </div>
            <div className="tc-product-body">
                <div className="tc-body-content">
                  <h1 className="tc-main-title">Sale Per Day</h1>
                  <Form.Select className="tc-select-box">
                      <option value="1">Week 1</option>
                      <option value="2">Week 2</option>
                      <option value="3">Week 3</option>
                  </Form.Select>
                  <div className="tc-daily-number">
                      <p class="tc-number-text"><span>March 2023</span></p>
                      <p class="tc-number-text"><span className="tc-text-success">₽20,000</span></p>
                  </div>
                  <div className="tc-daily-chart">
                    <ReactApexChart options={daily.options} series={daily.series} type="bar" height={250} />
                  </div>
                  <Table className="tc-table tc-daily-table tc-weekly-table">
                      <tbody>
                            {product.map((day,i) => {
                              return(
                                    <tr>
                                        <td>{day.name}</td>
                                        <td>{day.qty}</td>
                                        <td>{day.amount}</td>
                                        <td><div className="tc-sidebar-btn" onClick={() => {document.body.classList.add('tc-product1-sidebar');}} ><FontAwesomeIcon icon={faCircleArrowRight} /></div></td>
                                    </tr>
                                );
                            })}
                      </tbody>
                  </Table>
                </div>
              </div>
              <Secfooter />
        </div>
      </>
    );
};

export default Product1;
