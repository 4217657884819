import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Mainmodal from "../../component/Layout/Mainmodal";
import Cardmodal from "../../component/Layout/Cardmodal";
import Tcard from "../../component/Layout/Tcard";
import Product from "../Product/Product";
import Product1 from "../Product/Product1";
import Extrapolation from "../Extrapolation/Extrapolation";


const Home = () => {

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);

  const Mainitem =[
    {
      id: 1,
      serial: 'AB-03-0091-NX',
      date:'03/24/23 - 9:41 AM',
    },
    {
      id: 2,
      serial: 'AB-03-0091-NX',
      date:'03/24/23 - 9:41 AM',
    },
  ]

  const modallist =[
    {
      id: 1,
      heading: 'April 27, 2023',
      title: 'TC Today',
      total:'Total: ₽10,000',
      number:'No. of Card: 14',
    },
  ]

  const cardmodallist =[
    {
      id: 1,
      heading: 'AB-03-0091-NX',
      title: 'Patient Details',
    },
  ]

  const Details = [
      {
        id: 1,
        title: 'Age:',
        text: '63',
      },
      {
        id: 2,
        title: 'Sex:',
        text: 'Female',
      },
      {
        id: 3,
        title: 'Location:',
        text: 'Malate, Manila',
      },
      {
        id: 4,
        title: 'Birthdate:',
        text: 'April 2, 1960',
      },
  ]

  const Place = [
    {
      id: 1,
      title: 'MD:',
      text: 'Carlos, Juan',
    },
    {
      id: 2,
      title: 'MR:',
      text: 'Santos, Nina',
    },
    {
      id: 3,
      title: 'District:',
      text: 'North, Manila',
    },
  ]

  const data =[
    {
      id:'1',
      name:'md name',
      qty:'qty',
      amount:'amount',
    },
    {
      id:'2',
      name:'Carlos, Juan',
      qty:'4',
      amount:'2,910',
    },
    {
      id:'3',
      name:'Dela Cruz, Anna',
      qty:'2',
      amount:'1,150',
    },
    {
      id:'4',
      name:'Esguerra, Michael',
      qty:'3',
      amount:'2,365',
    },
    {
      id:'5',
      name:'Garcia, Claire',
      qty:'3',
      amount:'2,365',
    },
    {
      id:'6',
      name:'Montanilla-Cruz, jerome',
      qty:'2',
      amount:'1,210',
    },
  ]

  const product =[
    {
      id:'1',
      name:'product',
      qty:'qty',
      amount:'amount',
    },
    {
      id:'2',
      name:'Product 1',
      qty:'14',
      amount:'1,837',
    },
    {
      id:'3',
      name:'Product 2',
      qty:'2',
      amount:'2,150',
    },
    {
      id:'4',
      name:'Product 3',
      qty:'27',
      amount:'1,565',
    },
  ]

  const card = [
    {
        text:'t card',
    }
  ]
  


  return (
    <>
      <div className="tc-body-section">
        <div className="tc-body-content">
          <h1 className="tc-main-title">T-Card Live</h1>
          <div className="tc-number">
              <Button variant="transprent" className="tc-modal-btn" onClick={() => setModalShow(true)}>
                <p className="tc-number-text">Today: <span>14</span></p>
              </Button>
               {modallist.map((item,i) => {
                  return(
                    <Mainmodal show={modalShow} onHide={() => setModalShow(false)} 
                      heading={item.heading}
                      title={item.title}
                      total={item.total}
                      number={item.number}
                      data={data}
                    />
                  );
                })}
              <p className="tc-number-text">Total: <span> ₽ 10,000</span></p>
          </div>
              {Mainitem.map((item,i) => {
                  return(
                    <div className="tc-card-item">
                    <h2 className="tc-serial-number">{item.serial}</h2>
                    <p className="tc-date-text">{item.date}</p>
                    <div variant="transprent" onClick={() => setModalShow1(true)}>
                      <Tcard card={card} />
                    </div>
                      <table className="tc-place-section">
                          {Place.map((allplace,i) => 
                              {
                              return(
                                  <tr>
                                      <th>
                                          <h2 className="tc-place-title">{allplace.title}</h2>
                                      </th>
                                      <th>
                                          <p className="tc-place-name">{allplace.text}</p>
                                      </th>
                                  </tr>
                              );
                          })}
                      </table>
                  </div>
                  );
                })}
              {cardmodallist.map((item,i) => {
                return(
                  <Cardmodal show={modalShow1} onHide={() => setModalShow1(false)} 
                    heading={item.heading}
                    title={item.title}
                    product={product}
                    Details={Details}
                  />
                );
              })}
        </div>
        <Product />
        <Product1 />
        <Extrapolation />
      </div>
    </>
    );
};

export default Home;
