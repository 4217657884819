import React from "react";
import Modal from 'react-bootstrap/Modal';
import Maintable from "./Maintable";
import { Table } from "react-bootstrap";
import Tcard from "./Tcard";


const Cardmodal = (props) => {
  const card = [
    {
        text:'tipid card',
    }
]
  return (
    <>
     <Modal {...props} size="false" className="tc-model-dialog tc-card-model" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="tc-model-header" closeButton>
        <Modal.Title className="tc-model-header-title" id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tc-model-body">
        <h2 className="tc-model-title">{props.heading}</h2>
        <Tcard card={card} />
        <table className="tc-place-section">
            {props.Details.map((allplace,i) => 
                {
                return(
                    <tr>
                        <th>
                            <h2 className="tc-place-title">{allplace.title}</h2>
                        </th>
                        <th>
                            <p className="tc-place-name">{allplace.text}</p>
                        </th>
                    </tr>
                );
            })}
        </table>
          <Table className="tc-table">
            <tbody>
              {props.product.map((list,i) => {
                  return(
                    <Maintable 
                      name={list.name}
                      qty={list.qty}
                      amount={list.amount}
                    />
                  );
              })}
            </tbody>
          </Table>
      </Modal.Body>
    </Modal>
    </>
  )
};

export default Cardmodal;
