import React, { useState } from "react";
import { Outlet } from "react-router";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = () => {


return (
    <main className="tc-main-section">
      <div className="tc-container">
        <Navbar/>
        <Outlet />
        <Footer />
      </div>
    </main>
  );
};

export default Layout;
