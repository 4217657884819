import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Menubtn = () => {
    const [menuOpen, setMenuOpen] = useState(false);
return(
    <>
        <Navbar>
            <button type="button" className="navbar-toggler d-block" onClick={() => setMenuOpen(!menuOpen)}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <ul className={`${menuOpen && 'open'}`}>
                <li className="nav-item">
                    <Link to={'/'} className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                    <div onClick={() => {document.body.classList.add('tc-assigment-sidebar');}} className="nav-link">Assign Card</div>
                </li>
                <li className="nav-item">
                    <Link to={'/'} className="nav-link">Settings</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/'} className="nav-link">Sign Out</Link>
                </li>
            </ul>
        </Navbar>
    </>
  );
};

export default Menubtn;
