import { faCalendarDay, faCalendarDays, faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="tc-footer-section">
        <div className="tc-footer-content">
          <Link to="/Daily" className="tc-footer-tab">
            <FontAwesomeIcon icon={faCalendarDay} />
            daily
          </Link>
          <Link to="/Weekly" className="tc-footer-tab">
            <FontAwesomeIcon icon={faCalendarWeek} />
            weekly
          </Link>
          <Link to="/Monthly" className="tc-footer-tab">
            <FontAwesomeIcon icon={faCalendarDays} />
            monthly
          </Link>
        </div>
      </div>
    </>
  );
};
export default Footer;
