import React, { useState } from "react";
import { Link } from "react-router-dom";
import day from "../../images/days.png";
import week from "../../images/week.png";
import month from "../../images/month.png";

const Secfooter = () => {

  return (
      <>
         <div className="tc-product-footer">
            <div className="tc-product-footer-content">
                <div className="tc-footer-tab">
                    <img src={day} />
                    daily
                </div>
                <div className="tc-footer-tab">
                    <img src={week} />
                    weekly
                </div>
                <div className="tc-footer-tab">
                    <img src={month} />
                    monthly
                </div>
            </div>
        </div>
      </>
    );
};

export default Secfooter;
