import { faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Secfooter from "../../component/Layout/Secfooter";
import Nav from 'react-bootstrap/Nav';

const Extrapolation = () => {

  const product = [
    {
        id:'1',
        name:'md',
        qty:'rio',
    },
    {
        id:'2',
        name:'Carlos, Juan',
        qty:'12X',
        class:'tc-text-success'
    },
    {
        id:'3',
        name:'Esguerra, Mike',
        qty:'3X',
        class:'tc-text-danger'
    },
    {
        id:'4',
        name:'Garcia, Jimmy',
        qty:'2X',
        class:'tc-text-danger'
    },
    {
        id:'5',
        name:'Dela Cruz, John',
        qty:'1X',
        class:'tc-text-danger'
    },  
  ]

  return (
      <>
        <div className="tc-extrapolation-section tc-sidebar-section">
            <div className="tc-product-navbar">
              <div className="tc-product-menu" onClick={() => {document.body.classList.remove('tc-extrapolation-sidebar');}} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/></svg>
              </div>
              <div className="tc-product-text"><h2>Extrapolation</h2></div>
            </div>
            <div className="tc-product-body">
                <div className="tc-body-content">
                  <h1 className="tc-main-title">Top MDs</h1>
                  <div class="tc-daily-number"><p class="tc-number-text"><span>as of March 2023</span></p></div>  
                      <Form.Select className="tc-select-box">
                        <option value="1">ROI Report</option>
                        <option value="2">Budget</option>
                        <option value="3">Ext-Amount</option>
                      </Form.Select>
                      <Table className="tc-table tc-daily-table tc-weekly-table">
                        <tbody>
                              {product.map((day,i) => {
                                return(
                                      <tr>
                                          <td>{day.name}</td>
                                          <td className={day.class}>{day.qty}</td>
                                          <td><div className="tc-sidebar-btn"><FontAwesomeIcon icon={faCircleArrowRight} /></div></td>
                                      </tr>
                                  );
                              })}
                        </tbody>
                      </Table>
                  </div>
              </div>
              <Secfooter />
          </div>
      </>
    );
};

export default Extrapolation;
