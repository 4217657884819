import React from "react";
import Qrcode from '../../images/Qr-code.png'

const Tcard = (props) => {

  return (
    <>
      <div className="tc-card-section">
        <div className="tc-card-content">
            <h2 className="tc-card-text mt-0">Save on your medicines</h2>
            <div className="tc-card-qr">
                {props.card.map((item,i) => 
                    {
                    return(
                      <h2 className="tc-card-qr-text">{item.text}</h2>
                    );
                })}
                <div className="tc-card-qr-inner">
                    <img src={Qrcode} className="tc-card-qr-img" alt="QR code image" />
                    <p className="tc-card-qr-code">AA-99-999-AA</p>
                </div>
            </div>
            <h2 className="tc-card-text mb-0">go to tipidcard.com </h2>
        </div>
      </div>
    </>
    );
};

export default Tcard;
