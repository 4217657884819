import React from "react";

const Maintable = (props) => {
    
  return (
    <>
        <tr>
            <td>{props.name}</td>
            <td>{props.qty}</td>
            <td>{props.amount}</td>
        </tr>
    </>
    );
};

export default Maintable;
