import React from "react";
import getRoutes from "./routes";
import { useRoutes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/css/style.css"

const App = () => {
  const user = true;
  const routing = useRoutes(getRoutes(user));
  return <>{routing}</>;
};

export default App;
