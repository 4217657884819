import React from "react";
import Home from "./pages/Home/Home";
import Daily from "./pages/Report/Daily";
import Weekly from "./pages/Report/Weekly";
import Monthly from "./pages/Report/Monthly";
import Layout from "./component/Layout/MainLayout";
import { routes } from "./helpers/constants";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: user === true && <Layout />,
    children: [{ path: routes.homepage, element: <Home /> }],
  },
  {
    path: routes.dailypage,
    element: user === true && <Layout />,
    children: [{ path: routes.dailypage, element: <Daily /> }],
  },
  {
    path: routes.weeklypage,
    element: user === true && <Layout />,
    children: [{ path: routes.weeklypage, element: <Weekly /> }],
  },
  {
    path: routes.monthlypage,
    element: user === true && <Layout />,
    children: [{ path: routes.monthlypage, element: <Monthly /> }],
  },
];

export default getRoutes;
