import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Form, Table } from "react-bootstrap";
import { faCircleArrowRight, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Daily = () => {
    const [menuShow, setMenuShow] = useState(false)
    const [sideShow, setSideShow] = useState(false)
    const [rowShow, setRowShow] = useState(false)

    const [daily, setDaily] = useState({
        series: [{
            name: 'Net Profit',
            data: [13, 11, 12, 10, 10, 4]
        }],
        options: {
            chart: {
              type: 'bar',
              height: 250,
              toolbar: {
                show: false
              },
            },
            colors: ['#96da7a', '#fef99b'],
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                distributed: true,

              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['#000']
            },
            xaxis: {
              categories: ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'],
            },
            grid: {
                show: false,
            },
            fill: {
              opacity: 1
            },
            legend: {
                show: false,
            },
          },
    })
    const dailytable = [
        {
            id:'1',
            name:'day',
            qty:'qty',
            amount:'amount',
        },
        {
            id:'2',
            name:'1',
            qty:'14',
            amount:'10,000',
        },
        {
            id:'3',
            name:'2',
            qty:'13',
            amount:'8,000',
        },
        {
            id:'4',
            name:'3',
            qty:'13',
            amount:'8,000',
        },
        {
            id:'5',
            name:'4',
            qty:'12',
            amount:'7,500',
        },
        {
            id:'6',
            name:'5',
            qty:'12',
            amount:'7,000',
        },
        {
            id:'7',
            name:'6',
            qty:'5',
            amount:'3,120',
        },
        {
            id:'8',
            name:'7',
            qty:'0',
            amount:'0',
        },
    ]
    const day1 = [
        {
            id:'1',
            name:'day',
            qty:'qty',
            amount:'amount',
        },
        {
            id:'2',
            name:'Carlos, Juan',
            qty:'4',
            amount:'2,000',
        },
        {
            id:'3',
            name:'Dela Cruz, Anna',
            qty:'4',
            amount:'2,000',
        },
        {
            id:'4',
            name:'Esguerra, Michael',
            qty:'3',
            amount:'2,000',
        },
        {
            id:'5',
            name:'Garcia, Claire',
            qty:'2',
            amount:'1,100',
        },
        {
            id:'6',
            name:'Lapid, Jennifer',
            qty:'2',
            amount:'700',
        },
        {
            id:'7',
            name:'Montanilla-Cruz, jerome',
            qty:'2',
            amount:'1,200',
        },
    ]
  return (
        <>
            <div className="tc-body-section" >
                <div className="tc-body-content">
                    <h1 className="tc-main-title">TCard per Day</h1>
                    <div className="tc-daily-number">
                        <Form.Select className="tc-select-box">
                            <option value="1">Week 1 of March 2023</option>
                            <option value="1">Week 2 of March 2023</option>
                            <option value="1">Week 3 of March 2023</option>
                        </Form.Select>
                        <p class="tc-number-text">Total: <span className="tc-text-success"> ₽ 41,560</span></p>
                    </div>
                    <div className="tc-daily-chart">
                        <ReactApexChart options={daily.options} series={daily.series} type="bar" height={250} />
                    </div>
                    <Table className="tc-table tc-daily-table">
                        <tbody>
                            {dailytable.map((list,i) => {
                                return(
                                    <tr>
                                        <td>{list.name}</td>
                                        <td>{list.qty}</td>
                                        <td>{list.amount}</td>
                                        <td><div className="tc-sidebar-btn" onClick={() => setMenuShow(!menuShow)} ><FontAwesomeIcon icon={faCircleArrowRight} /></div></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <div className={`tc-sidebar-section ${menuShow && 'zl-sidebar-open'}`}>
                        <div className="tc-navbar-section tc-report-navbar">  
                            <div className="tc-navbar-content">
                                <div className="tc-navbar-menu">
                                    <div className="tc-navbar-back" onClick={() => setMenuShow(false)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="tc-navbar-text">
                                    <h2>Client1</h2>
                                </div>
                                <div className="tc-navbar-icon" onClick={() => {document.body.classList.add('tc-product-sidebar');}} >
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.99999 2.82349C5.41521 2.82349 4.94116 3.29754 4.94116 3.88231V5.82349C4.94116 6.40826 5.41521 6.88231 5.99999 6.88231H18C18.5848 6.88231 19.0588 6.40826 19.0588 5.82349V3.88231C19.0588 3.29754 18.5848 2.82349 18 2.82349H5.99999Z" />
                                      <path fillRule="evenodd" clipRule="evenodd" d="M6.35293 7.41172H17.8235V18.3529C17.8235 19.9123 16.5594 21.1764 15 21.1764H9.17646C7.61707 21.1764 6.35293 19.9123 6.35293 18.3529V7.41172ZM13.2353 10.4117H10.7647V12.5294H8.64704V15H10.7647V17.1176H13.2353V15H15.3529V12.5294H13.2353V10.4117Z" />
                                  </svg>
                                </div>
                            </div>
                        </div>
                        <div className="tc-body-content">
                            <h1 className="tc-main-title">Day 1</h1>
                            <div className="tc-daily-number">
                                <p class="tc-number-text"><span>March 1 2023</span></p>
                                <p class="tc-number-text"> <span>Total:</span> <span className="tc-text-success"> ₽ 41,560</span></p>
                            </div>
                            <Table className="tc-table tc-daily-table">
                                <tbody>
                                    {day1.map((day,i) => {
                                        return(
                                            <tr>
                                                <td>{day.name}</td>
                                                <td>{day.qty}</td>
                                                <td>{day.amount}</td>
                                                <td><div className="tc-sidebar-btn" onClick={() => setSideShow(!sideShow)} ><FontAwesomeIcon icon={faCircleArrowRight} /></div></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className={`tc-sidebar-section ${sideShow && 'zl-sidebar-open'}`}>
                        <div className="tc-navbar-section tc-report-navbar">  
                            <div className="tc-navbar-content">
                                <div className="tc-navbar-menu">
                                    <div className="tc-navbar-back" onClick={() => setSideShow(false)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="tc-navbar-text">
                                    <h2>Client1</h2>
                                </div>
                                <div className="tc-navbar-icon" onClick={() => {document.body.classList.add('tc-product-sidebar');}} >
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.99999 2.82349C5.41521 2.82349 4.94116 3.29754 4.94116 3.88231V5.82349C4.94116 6.40826 5.41521 6.88231 5.99999 6.88231H18C18.5848 6.88231 19.0588 6.40826 19.0588 5.82349V3.88231C19.0588 3.29754 18.5848 2.82349 18 2.82349H5.99999Z" />
                                      <path fillRule="evenodd" clipRule="evenodd" d="M6.35293 7.41172H17.8235V18.3529C17.8235 19.9123 16.5594 21.1764 15 21.1764H9.17646C7.61707 21.1764 6.35293 19.9123 6.35293 18.3529V7.41172ZM13.2353 10.4117H10.7647V12.5294H8.64704V15H10.7647V17.1176H13.2353V15H15.3529V12.5294H13.2353V10.4117Z" />
                                  </svg>
                                </div>
                            </div>
                        </div>
                        <div className="tc-body-content">
                            <h1 className="tc-main-title">Carlos, Juan</h1>
                            <div className="tc-daily-number">
                                <p class="tc-number-text"><span>March 1 2023</span></p>
                                <p class="tc-number-text"> <span>Total:</span> <span className="tc-text-success"> ₽10,000</span></p>
                            </div>
                            <Table className="tc-table tc-daily-table">
                                <tbody>
                                    <tr>
                                        <td>tc serial</td>
                                        <td>amount</td>
                                        <td><div className="tc-menu-btn" ><FontAwesomeIcon icon={faEllipsis} /></div></td>
                                    </tr>
                                    <tr>
                                        <td>AB-03-0091-NX</td>
                                        <td>500</td>
                                        <td><div className="tc-menu-btn" onClick={() => setRowShow(!rowShow)} ><FontAwesomeIcon icon={faEllipsis} /></div></td>
                                    </tr>
                                    <tr className={`tc-hide-row ${rowShow && 'zl-show-row'}`}>
                                        <td>
                                            <h2>Product</h2>
                                            <p>Product 1</p>
                                            <p>Product 2</p>
                                        </td>
                                        <td>
                                            <p>300</p>
                                            <p>200</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>AB-03-0095-NX</td>
                                        <td>600</td>
                                        <td><div className="tc-menu-btn" ><FontAwesomeIcon icon={faEllipsis} /></div></td>
                                    </tr>
                                    <tr>
                                        <td>AB-03-0093-NX</td>
                                        <td>400</td>
                                        <td><div className="tc-menu-btn" ><FontAwesomeIcon icon={faEllipsis} /></div></td>
                                    </tr>
                                    <tr>
                                        <td>AB-03-0092-NX</td>
                                        <td>500</td>
                                        <td><div className="tc-menu-btn" ><FontAwesomeIcon icon={faEllipsis} /></div></td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Daily;